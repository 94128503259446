#wdContainer {
  padding: 20px;
  margin-top: 30px;
  margin-bottom: 50px;
  background: none;
  opacity: 0.8;
  color: white;
}

#wdTitleContainer {
  padding: 20px;
  margin-top: 30px;
  margin-bottom: 10px;
  color: white;
}

#wdRow {
  width: 100%;
  overflow: hidden;
}

#wdHeader,
#wdHeader2 {
  border-right: 0.15em solid;
  white-space: nowrap;
  opacity: 0;
  overflow: hidden;
  margin-bottom: 2rem;
  font-size: 36px;
  -webkit-animation: type 2s steps(100, end);
  animation: type 2s steps(100, end);
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

#wdHeader {
  width: 10em;
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

#wdHeader2 {
  width: 9.5em;
  -webkit-animation-delay: 2.5s;
  animation-delay: 2.5s;
}

/* iPad Pro 10.5 screen */
@media only screen and (min-device-width: 834px) and (max-device-width: 991px) and (-webkit-min-device-pixel-ratio: 2) {
  #wdHeader,
  #wdHeader2 {
    font-size: 30px;
  }
}

/* iPhone X screen */
@media only screen and (min-device-width: 375px) and (max-device-width: 833px) and (-webkit-min-device-pixel-ratio: 3) {
  #wdHeader,
  #wdHeader2 {
    font-size: 24px;
  }
}

@keyframes type {
  0% {
    width: 0;
  }
  1% {
    opacity: 1;
  }
  99.9% {
    border-right: 0.15em solid white;
  }
  100% {
    opacity: 1;
    border: none;
  }
}

@-webkit-keyframes type {
  0% {
    width: 0;
  }
  1% {
    opacity: 1;
  }
  99.9% {
    border-right: 0.15em solid white;
  }
  100% {
    opacity: 1;
    border: none;
  }
}
