#navbarHome {
  opacity: 0;
  animation: fadeIn linear 1s;
  -webkit-animation: fadeIn linear 1s;
  -moz-animation: fadeIn linear 1s;
  -o-animation: fadeIn linear 1s;
  -ms-animation: fadeIn linear 1s;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  -o-animation-fill-mode: forwards;
  -ms-animation-fill-mode: forwards;
  animation-delay: 15s;
  -webkit-animation-delay: 15s;
  -moz-animation-delay: 15s;
  -o-animation-delay: 15s;
  -ms-animation-delay: 15s;
}

#wdProjectsBtnHome,
#uxProjectsBtnHome,
#aboutBtnHome,
#blogBtnHome {
  font-size: 25px;
  color: white;
  margin-right: 30px;
  opacity: 0;
  animation: fadeIn linear 1s;
  -webkit-animation: fadeIn linear 1s;
  -moz-animation: fadeIn linear 1s;
  -o-animation: fadeIn linear 1s;
  -ms-animation: fadeIn linear 1s;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  -o-animation-fill-mode: forwards;
  -ms-animation-fill-mode: forwards;
}
#wdProjectsBtnHome {
  animation-delay: 16s;
  -webkit-animation-delay: 16s;
  -moz-animation-delay: 16s;
  -o-animation-delay: 16s;
  -ms-animation-delay: 16s;
}
#uxProjectsBtnHome {
  animation-delay: 16.5s;
  -webkit-animation-delay: 16.5s;
  -moz-animation-delay: 16.5s;
  -o-animation-delay: 16.5s;
  -ms-animation-delay: 16.5s;
}
#aboutBtnHome {
  animation-delay: 17s;
  -webkit-animation-delay: 17s;
  -moz-animation-delay: 17s;
  -o-animation-delay: 17s;
  -ms-animation-delay: 17s;
}
#blogBtnHome {
  animation-delay: 17.5s;
  -webkit-animation-delay: 17.5s;
  -moz-animation-delay: 17.5s;
  -o-animation-delay: 17.5s;
  -ms-animation-delay: 17.5s;
}

#logoHome {
  height: 150px;
  width: 150px;
  margin: 0px;
  opacity: 0;
  animation: fadeIn linear 1s;
  -webkit-animation: fadeIn linear 1s;
  -moz-animation: fadeIn linear 1s;
  -o-animation: fadeIn linear 1s;
  -ms-animation: fadeIn linear 1s;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  -o-animation-fill-mode: forwards;
  -ms-animation-fill-mode: forwards;
  animation-delay: 15.5s;
  -webkit-animation-delay: 15.5s;
  -moz-animation-delay: 15.5s;
  -o-animation-delay: 15.5s;
  -ms-animation-delay: 15.5s;
}

#wdProjectsBtnHome:hover,
#uxProjectsBtnHome:hover,
#aboutBtnHome:hover,
#blogBtnHome:hover {
  background-color: rgb(221, 89, 1);
}

#wdProjectsBtnHome:focus,
#uxProjectsBtnHome:focus,
#aboutBtnHome:focus,
#blogBtnHome:focus {
  background-color: rgb(221, 89, 1);
}

#homeContainer {
  margin-top: 50px;
  /* background: none; */
  color: white;
  text-align: center;
}

#greetingContainer {
  position: relative;
  top: 20vh;
  left: 0;
}

.innerDiv {
  top: 0%;
  left: 0%;
  width: 100%;
  position: absolute;
}

#homeGreeting,
#homeHeader,
#homeHeader2,
#homeText {
  opacity: 0;
  text-align: center;
  display: block;
}

#homeGreeting {
  animation: fade linear 4s;
  -webkit-animation: fade linear 4s;
  -moz-animation: fade linear 4s;
  -o-animation: fade linear 4s;
  -ms-animation: fade linear 4s;
}

#homeHeader,
#homeHeader2 {
  animation: fade linear 5s;
  -webkit-animation: fade linear 5s;
  -moz-animation: fade linear 5s;
  -o-animation: fade linear 5s;
  -ms-animation: fade linear 5s;
}

#homeText {
  animation: fadeIn linear 2s;
  -webkit-animation: fadeIn linear 2s;
  -moz-animation: fadeIn linear 2s;
  -o-animation: fadeIn linear 2s;
  -ms-animation: fadeIn linear 2s;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  -o-animation-fill-mode: forwards;
  -ms-animation-fill-mode: forwards;
  z-index: 103;
  animation-delay: 14s;
  -webkit-animation-delay: 14s;
  -moz-animation-delay: 14s;
  -o-animation-delay: 14s;
  -ms-animation-delay: 14s;
}

#homeGreeting {
  z-index: 100;
}

#homeHeader {
  z-index: 101;
  animation-delay: 4s;
  -webkit-animation-delay: 4s;
  -moz-animation-delay: 4s;
  -o-animation-delay: 4s;
  -ms-animation-delay: 4s;
}

#homeHeader2 {
  z-index: 102;
  animation-delay: 9s;
  -webkit-animation-delay: 9s;
  -moz-animation-delay: 9s;
  -o-animation-delay: 9s;
  -ms-animation-delay: 9s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-moz-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-o-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-ms-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* Custom Footer CSS */

#cFooter {
  background-color: rgba(0, 0, 0, 0.5);
  height: 120px;
  width: 100%;
  bottom: 0px;
  left: 0px;
  position: fixed;
  opacity: 0;
  animation: fadeIn linear 1s;
  -webkit-animation: fadeIn linear 1s;
  -moz-animation: fadeIn linear 1s;
  -o-animation: fadeIn linear 1s;
  -ms-animation: fadeIn linear 1s;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  -o-animation-fill-mode: forwards;
  -ms-animation-fill-mode: forwards;
  animation-delay: 15s;
  -webkit-animation-delay: 15s;
  -moz-animation-delay: 15s;
  -o-animation-delay: 15s;
  -ms-animation-delay: 15s;
}

#cTwitterFooterBtn,
#cGitHubFooterBtn,
#cLinkedInFooterBtn,
#cCvFooterBtn,
#cEmailFooterBtn,
#cPhoneFooterBtn {
  color: white;
  font-weight: bolder;
  font-size: 21px;
  background-color: transparent;
  border: 0px;
  margin: 10px;
  opacity: 0;
  animation: fadeIn linear 1s;
  -webkit-animation: fadeIn linear 1s;
  -moz-animation: fadeIn linear 1s;
  -o-animation: fadeIn linear 1s;
  -ms-animation: fadeIn linear 1s;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  -o-animation-fill-mode: forwards;
  -ms-animation-fill-mode: forwards;
}

#cTwitterFooterBtn:hover,
#cGitHubFooterBtn:hover,
#cLinkedInFooterBtn:hover,
#cCvFooterBtn:hover,
#cEmailFooterBtn:hover,
#cPhoneFooterBtn:hover {
  background-color: rgb(219, 101, 2);
}

#cCreditText {
  font-size: 3px;
  color: white;
}

#cTwitterFooterBtn {
  animation-delay: 16s;
  -webkit-animation-delay: 16s;
  -moz-animation-delay: 16s;
  -o-animation-delay: 16s;
  -ms-animation-delay: 16s;
}
#cGitHubFooterBtn {
  animation-delay: 16.5s;
  -webkit-animation-delay: 16.5s;
  -moz-animation-delay: 16.5s;
  -o-animation-delay: 16.5s;
  -ms-animation-delay: 16.5s;
}
#cLinkedInFooterBtn {
  animation-delay: 17s;
  -webkit-animation-delay: 17s;
  -moz-animation-delay: 17s;
  -o-animation-delay: 17s;
  -ms-animation-delay: 17s;
}
#cCvFooterBtn {
  animation-delay: 17.5s;
  -webkit-animation-delay: 17.5s;
  -moz-animation-delay: 17.5s;
  -o-animation-delay: 17.5s;
  -ms-animation-delay: 17.5s;
}
#cEmailFooterBtn {
  animation-delay: 18s;
  -webkit-animation-delay: 18s;
  -moz-animation-delay: 18s;
  -o-animation-delay: 18s;
  -ms-animation-delay: 18s;
}
#cPhoneFooterBtn {
  animation-delay: 18.5s;
  -webkit-animation-delay: 18.5s;
  -moz-animation-delay: 18.5s;
  -o-animation-delay: 18.5s;
  -ms-animation-delay: 18.5s;
}

/* iPad Pro 10.5 screen */
@media only screen and (min-device-width: 334px) and (max-device-width: 991px) and (-webkit-min-device-pixel-ratio: 2) {
  #navbarHome {
    animation-delay: 1s;
    -webkit-animation-delay: 1s;
    -moz-animation-delay: 1s;
    -o-animation-delay: 1s;
    -ms-animation-delay: 1s;
  }

  #cFooter {
    height: 175px;
    overflow-y: scroll;
  }

  #wdProjectsBtn,
  #uxProjectsBtn,
  #aboutBtn,
  #blogBtn {
    font-size: 18px;
    margin-right: 15px;
    font-weight: 600;
  }

  #wdProjectsBtnHome {
    animation-delay: 0.5s;
    -webkit-animation-delay: 0.5s;
    -moz-animation-delay: 0.5s;
    -o-animation-delay: 0.5s;
    -ms-animation-delay: 0.5s;
  }
  #uxProjectsBtnHome {
    animation-delay: 0.75s;
    -webkit-animation-delay: 0.75s;
    -moz-animation-delay: 0.75s;
    -o-animation-delay: 0.75s;
    -ms-animation-delay: 0.75s;
  }
  #aboutBtnHome {
    animation-delay: 1s;
    -webkit-animation-delay: 1s;
    -moz-animation-delay: 1s;
    -o-animation-delay: 1s;
    -ms-animation-delay: 1s;
  }
  #blogBtnHome {
    animation-delay: 1.25s;
    -webkit-animation-delay: 1.25s;
    -moz-animation-delay: 1.25s;
    -o-animation-delay: 1.25s;
    -ms-animation-delay: 1.25s;
  }

  #logoHome {
    height: 100px;
    width: 100px;
    animation-delay: 1s;
    -webkit-animation-delay: 1s;
    -moz-animation-delay: 1s;
    -o-animation-delay: 1s;
    -ms-animation-delay: 1s;
  }

  #homeGreeting,
  #homeHeader,
  #homeHeader2,
  #homeText {
    font-size: 28px;
  }
}

/* iPhone X screen */
@media only screen and (min-device-width: 312px) and (max-device-width: 833px) and (-webkit-min-device-pixel-ratio: 3) {
  #wdProjectsBtnHome,
  #uxProjectsBtnHome,
  #aboutBtnHome,
  #blogBtnHome {
    font-size: 15px;
    margin-right: 15px;
    font-weight: 600;
  }

  #wdProjectsBtnHome {
    animation-delay: 0.5s;
    -webkit-animation-delay: 0.5s;
    -moz-animation-delay: 0.5s;
    -o-animation-delay: 0.5s;
    -ms-animation-delay: 0.5s;
  }
  #uxProjectsBtnHome {
    animation-delay: 0.75s;
    -webkit-animation-delay: 0.75s;
    -moz-animation-delay: 0.75s;
    -o-animation-delay: 0.75s;
    -ms-animation-delay: 0.75s;
  }
  #aboutBtnHome {
    animation-delay: 1s;
    -webkit-animation-delay: 1s;
    -moz-animation-delay: 1s;
    -o-animation-delay: 1s;
    -ms-animation-delay: 1s;
  }
  #blogBtnHome {
    animation-delay: 1.25s;
    -webkit-animation-delay: 1.25s;
    -moz-animation-delay: 1.25s;
    -o-animation-delay: 1.25s;
    -ms-animation-delay: 1.25s;
  }

  #logoHome {
    height: 75px;
    width: 75px;
    animation-delay: 1s;
    -webkit-animation-delay: 1s;
    -moz-animation-delay: 1s;
    -o-animation-delay: 1s;
    -ms-animation-delay: 1s;
  }

  #greetingContainer {
    position: relative;
    top: 15vh;
    left: 0;
  }

  #homeGreeting,
  #homeHeader,
  #homeHeader2,
  #homeText {
    font-size: 24px;
  }

  #cFooter {
    height: 120px;
    overflow-y: scroll;
  }

  #cTwitterFooterBtn,
  #cGitHubFooterBtn,
  #cLinkedInFooterBtn,
  #cCvFooterBtn,
  #cEmailFooterBtn,
  #cPhoneFooterBtn {
    font-size: 13px;
    margin: 6px;
  }

  #cCreditText {
    font-size: 3px;
  }
}
/* 
#toggleAnimationButton {
  top: 50%;
  right: 50;
} */
