.scrollTop {
  position: fixed;
  bottom: 500px;
  right: 120px;
  size: 5x;
  fill: white;
  align-items: center;
  height: 40px;
  justify-content: center;
  z-index: 1000;
  cursor: pointer;
  animation: fadeIn 0.4s;
  transition: opacity 0.5s;
  opacity: 0.6;
}

.scrollTop:hover {
  fill: rgb(242, 130, 78);
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}

/* iPad Pro 10.5 screen */
@media only screen and (min-device-width: 834px) and (max-device-width: 1112px) and (-webkit-min-device-pixel-ratio: 2) {
  #scrollTop {
    bottom: 450px;
    right: 40px;
  }
}

/* iPhone X screen */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
  #scrollTop {
    bottom: 400px;
    right: 20px;
  }
}
