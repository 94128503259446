#wdProjectsBtn,
#uxProjectsBtn,
#aboutBtn,
#blogBtn {
  font-size: 25px;
  color: white;
  margin-right: 30px;
}

#logo {
  height: 150px;
  width: 150px;
  margin: 0px;
}

#wdProjectsBtn:hover,
#uxProjectsBtn:hover,
#aboutBtn:hover,
#blogBtn:hover {
  background-color: rgb(221, 89, 1);
}

#wdProjectsBtn:focus,
#uxProjectsBtn:focus,
#aboutBtn:focus,
#blogBtn:focus {
  background-color: rgb(221, 89, 1);
}

/* iPad Pro 10.5 screen */
@media only screen and (min-device-width: 834px) and (max-device-width: 991px) and (-webkit-min-device-pixel-ratio: 2) {
  #wdProjectsBtn,
  #uxProjectsBtn,
  #aboutBtn,
  #blogBtn {
    font-size: 18px;
    margin-right: 15px;
    font-weight: 600;
    opacity: 0;
    animation: fadeIn linear 1s;
    -webkit-animation: fadeIn linear 1s;
    -moz-animation: fadeIn linear 1s;
    -o-animation: fadeIn linear 1s;
    -ms-animation: fadeIn linear 1s;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    -ms-animation-fill-mode: forwards;
  }

  #wdProjectsBtn {
    animation-delay: 0.5s;
    -webkit-animation-delay: 0.5s;
    -moz-animation-delay: 0.5s;
    -o-animation-delay: 0.5s;
    -ms-animation-delay: 0.5s;
  }
  #uxProjectsBtn {
    animation-delay: 0.75s;
    -webkit-animation-delay: 0.75s;
    -moz-animation-delay: 0.75s;
    -o-animation-delay: 0.75s;
    -ms-animation-delay: 0.75s;
  }
  #aboutBtn {
    animation-delay: 1s;
    -webkit-animation-delay: 1s;
    -moz-animation-delay: 1s;
    -o-animation-delay: 1s;
    -ms-animation-delay: 1s;
  }
  #blogBtn {
    animation-delay: 1.25s;
    -webkit-animation-delay: 1.25s;
    -moz-animation-delay: 1.25s;
    -o-animation-delay: 1.25s;
    -ms-animation-delay: 1.25s;
  }

  #logo {
    height: 100px;
    width: 100px;
  }
}

/* iPhone X screen */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
  #wdProjectsBtn,
  #uxProjectsBtn,
  #aboutBtn,
  #blogBtn {
    font-size: 15px;
    margin-right: 15px;
    font-weight: 600;
    opacity: 0;
    animation: fadeIn linear 1s;
    -webkit-animation: fadeIn linear 1s;
    -moz-animation: fadeIn linear 1s;
    -o-animation: fadeIn linear 1s;
    -ms-animation: fadeIn linear 1s;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    -ms-animation-fill-mode: forwards;
  }

  #wdProjectsBtn {
    animation-delay: 0.5s;
    -webkit-animation-delay: 0.5s;
    -moz-animation-delay: 0.5s;
    -o-animation-delay: 0.5s;
    -ms-animation-delay: 0.5s;
  }
  #uxProjectsBtn {
    animation-delay: 0.75s;
    -webkit-animation-delay: 0.75s;
    -moz-animation-delay: 0.75s;
    -o-animation-delay: 0.75s;
    -ms-animation-delay: 0.75s;
  }
  #aboutBtn {
    animation-delay: 1s;
    -webkit-animation-delay: 1s;
    -moz-animation-delay: 1s;
    -o-animation-delay: 1s;
    -ms-animation-delay: 1s;
  }
  #blogBtn {
    animation-delay: 1.25s;
    -webkit-animation-delay: 1.25s;
    -moz-animation-delay: 1.25s;
    -o-animation-delay: 1.25s;
    -ms-animation-delay: 1.25s;
  }

  #logo {
    height: 75px;
    width: 75px;
  }
}
