#blogContainer {
  padding: 20px;
  margin-top: 30px;
  margin-bottom: 50px;
  background: none;
  opacity: 0.8;
  color: white;
}

#blogTitleContainer {
  padding: 20px;
  margin-top: 30px;
  margin-bottom: 10px;
  color: white;
}

#blogHeader,
#blogHeader2 {
  border-right: 0.15em solid;
  white-space: nowrap;
  opacity: 0;
  overflow: hidden;
  margin-bottom: 2rem;
  font-size: 36px;
  -webkit-animation: type 3s steps(100, end);
  animation: type 3s steps(100, end);
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

#blogHeader {
  width: 14em;
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

#blogHeader2 {
  width: 15.5em;
  -webkit-animation-delay: 3.5s;
  animation-delay: 3.5s;
}

/* iPad Pro 10.5 screen */
@media only screen and (min-device-width: 834px) and (max-device-width: 991px) and (-webkit-min-device-pixel-ratio: 2) {
  #blogHeader,
  #blogHeader2 {
    font-size: 30px;
  }
}

/* iPhone X screen */
@media only screen and (min-device-width: 375px) and (max-device-width: 833px) and (-webkit-min-device-pixel-ratio: 3) {
  #blogHeader,
  #blogHeader2 {
    font-size: 20px;
  }
}
