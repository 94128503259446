#aboutContainer,
#aboutContainer2 {
  font-family: "Maven Pro";
  padding: 20px;
  margin-top: 30px;
  margin-bottom: 50px;
  color: white;
  font-size: 20px;
  font-weight: 600;
}

#bioCard2 {
  background-color: inherit;
  color: white;
  opacity: 0;
  animation: fadeIn linear 1s;
  -webkit-animation: fadeIn linear 1s;
  -moz-animation: fadeIn linear 1s;
  -o-animation: fadeIn linear 1s;
  -ms-animation: fadeIn linear 1s;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  -o-animation-fill-mode: forwards;
  -ms-animation-fill-mode: forwards;
  animation-delay: 4.5s;
  -webkit-animation-delay: 4.5s;
  -moz-animation-delay: 4.5s;
  -o-animation-delay: 4.5s;
  -ms-animation-delay: 4.5s;
}

p {
  font-size: 20px;
}

#portrait {
  display: block;
  height: 200px;
  width: 200px;
  border: 2px solid white;
  border-radius: 50%;
  margin: 0px auto 60px auto;
  opacity: 0;
  animation: fadeIn linear 1s;
  -webkit-animation: fadeIn linear 1s;
  -moz-animation: fadeIn linear 1s;
  -o-animation: fadeIn linear 1s;
  -ms-animation: fadeIn linear 1s;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  -o-animation-fill-mode: forwards;
  -ms-animation-fill-mode: forwards;
  animation-delay: 0.5s;
  -webkit-animation-delay: 0.5s;
  -moz-animation-delay: 0.5s;
  -o-animation-delay: 0.5s;
  -ms-animation-delay: 0.5s;
}

#aboutGreeting {
  text-align: center;
  font-weight: bolder;
  opacity: 0;
  animation: fadeIn linear 1s;
  -webkit-animation: fadeIn linear 1s;
  -moz-animation: fadeIn linear 1s;
  -o-animation: fadeIn linear 1s;
  -ms-animation: fadeIn linear 1s;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  -o-animation-fill-mode: forwards;
  -ms-animation-fill-mode: forwards;
  animation-delay: 1s;
  -webkit-animation-delay: 1s;
  -moz-animation-delay: 1s;
  -o-animation-delay: 1s;
  -ms-animation-delay: 1s;
}

#signatureLogo {
  display: block;
  height: 250px;
  width: 250px;
  margin: 0px auto 60px auto;
}

.btn-lg {
  background-color: transparent;
  color: #fff;
  border: 0px;
  margin: 10px;
}

#bioCard {
  background-color: inherit;
  color: white;
}

#twitterBtn,
#gitHubBtn,
#linkedInBtn,
#cvBtn,
#emailBtn,
#phoneBtn {
  color: white;
  font-weight: bolder;
  font-size: 24px;
  opacity: 0;
  animation: fadeIn linear 1s;
  -webkit-animation: fadeIn linear 1s;
  -moz-animation: fadeIn linear 1s;
  -o-animation: fadeIn linear 1s;
  -ms-animation: fadeIn linear 1s;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  -o-animation-fill-mode: forwards;
  -ms-animation-fill-mode: forwards;
}

#twitterBtn {
  animation-delay: 1.5s;
  -webkit-animation-delay: 1.5s;
  -moz-animation-delay: 1.5s;
  -o-animation-delay: 1.5s;
  -ms-animation-delay: 1.5s;
}
#gitHubBtn {
  animation-delay: 2s;
  -webkit-animation-delay: 2s;
  -moz-animation-delay: 2s;
  -o-animation-delay: 2s;
  -ms-animation-delay: 2s;
}
#linkedInBtn {
  animation-delay: 2.5s;
  -webkit-animation-delay: 2.5s;
  -moz-animation-delay: 2.5s;
  -o-animation-delay: 2.5s;
  -ms-animation-delay: 2.5s;
}
#cvBtn {
  animation-delay: 3s;
  -webkit-animation-delay: 3s;
  -moz-animation-delay: 3s;
  -o-animation-delay: 3s;
  -ms-animation-delay: 3s;
}
#emailBtn {
  animation-delay: 3.5s;
  -webkit-animation-delay: 3.5s;
  -moz-animation-delay: 3.5s;
  -o-animation-delay: 3.5s;
  -ms-animation-delay: 3.5s;
}
#phoneBtn {
  animation-delay: 4s;
  -webkit-animation-delay: 4s;
  -moz-animation-delay: 4s;
  -o-animation-delay: 4s;
  -ms-animation-delay: 4s;
}

.btn-lg:hover {
  background-color: rgb(249, 101, 2);
}

/* iPad Pro 10.5 screen */
@media only screen and (min-device-width: 834px) and (max-device-width: 991px) and (-webkit-min-device-pixel-ratio: 2) {
  #twitterBtn,
  #gitHubBtn,
  #linkedInBtn,
  #cvBtn,
  #emailBtn,
  #phoneBtn {
    font-size: 20px;
  }

  #portrait {
    height: 175px;
    width: 175px;
  }
}

/* iPhone X screen */
@media only screen and (min-device-width: 375px) and (max-device-width: 833px) and (-webkit-min-device-pixel-ratio: 3) {
  #twitterBtn,
  #gitHubBtn,
  #linkedInBtn,
  #cvBtn,
  #emailBtn,
  #phoneBtn {
    font-size: 18px;
  }

  #portrait {
    height: 150px;
    width: 150px;
  }

  #signatureLogo {
    height: 150px;
    width: 150px;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
